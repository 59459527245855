import React, { useContext, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import CookiesBanner from "./components/CookiesBanner";
import { ErrorBoundary } from "./components/utils/ErrorBoundary";
import {
  ColorModeContext,
  ToggleContext,
  WidthContext,
} from "./components/utils/Providers";
import { ConsentContext } from "./context/ConsentContext";
import Routes from "./routes";

function App() {
  const { consent } = useContext(ConsentContext);
  const location = useLocation();

  const [color, setColor] = React.useState("black");
  const [width, setWidth] = React.useState(window.innerWidth);
  const [toggle, setToggle] = React.useState(false);

  function gtag() {
    window.dataLayer.push(arguments);
  }

  useEffect(() => {
    try {
      if (consent && process.env.NODE_ENV === "production") {
        gtag("consent", "update", {
          analytics_storage: consent.analytics_storage ? "granted" : "denied",
          ad_storage: consent.ad_storage ? "granted" : "denied",
          ad_user_data: consent.ad_user_data ? "granted" : "denied",
          ad_personalization: consent.ad_personalization ? "granted" : "denied",
          personalization_storage: consent.personalization_storage
            ? "granted"
            : "denied",
          functionality_storage: "granted", // Supposant que cela reste toujours activé
          security_storage: "granted", // Supposant que cela reste toujours activé
        });
      }
    } catch (error) {
      console.error("Error reading or updating cookie consent:", error);
    }
  }, [consent]);

  useEffect(() => {
    // Suivi de la pageview si le consentement pour les analytics est accordé
    gtag("event", "pageview", {
      page_path: window.location.pathname + window.location.search,
    });
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <HelmetProvider>
        <WidthContext.Provider value={{ width, setWidth }}>
          <ColorModeContext.Provider value={{ color, setColor }}>
            <ToggleContext.Provider value={{ toggle, setToggle }}>
              <ErrorBoundary>
                <Routes />
                <CookiesBanner />
              </ErrorBoundary>
            </ToggleContext.Provider>
          </ColorModeContext.Provider>
        </WidthContext.Provider>
      </HelmetProvider>
    </div>
  );
}

export default App;
