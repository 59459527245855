import React from "react";
import { Helmet } from "react-helmet-async";

export default function MetaTagsMaster({
    title,
    description,
    image,
    keywords,
    url,
    robots = true,
    jsonLd,
}) {
    const fullTitle = title ? `${title} - Pizza Cook` : "Pizza Cook";

    // Utilisation de `new URL` pour une gestion plus propre de l'URL
    const currentUrl = (() => {
        try {
            const baseUrl = new URL(url || window.location.href);
            baseUrl.search = ""; // Supprime les paramètres de requête
            return baseUrl.toString();
        } catch (error) {
            console.error("Invalid URL:", error);
            return url || window.location.href.split("?")[0];
        }
    })();

    const defaultDescription =
        "Découvrez nos pizzerias à Saint-Sylvain-D'Anjou et Seiches-sur-le-Loir. Savourez nos pizzas artisanales, réalisées avec des ingrédients de qualité.";

    const keywordsDefault =
        "pizza, pizzeria, artisanal, saint-sylvain-d'anjou, seiches-sur-le-loir, saint-sylvain-d'anjou-seiches-sur-le-loir";

    const imageDefault = "%PUBLIC_URL%/android-icon-192x192.png";

    return (
        <Helmet>
            <title>{fullTitle}</title>
            <meta name="title" content={fullTitle} />
            <meta
                name="robots"
                content={robots ? "index, follow" : "noindex, nofollow"}
            />
            <meta
                name="description"
                content={description || defaultDescription}
            />
            <link rel="canonical" href={currentUrl} />
            <meta name="keywords" content={keywords || keywordsDefault} />

            {/* Open Graph / Facebook */}
            <meta property="og:url" content={currentUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={fullTitle} />
            <meta property="og:site_name" content="Pizza Cook" />
            <meta
                property="og:description"
                content={description || defaultDescription}
            />
            <meta property="og:image" content={image || imageDefault} />

            {/* Twitter */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@pizza-cook" />
            <meta name="twitter:url" content={currentUrl} />
            <meta name="twitter:title" content={fullTitle} />
            <meta
                name="twitter:description"
                content={description || defaultDescription}
            />
            <meta name="twitter:image" content={image || imageDefault} />

            {/* JSON-LD */}
            {jsonLd && (
                <script type="application/ld+json">
                    {JSON.stringify(jsonLd, null, 2)}
                </script>
            )}
        </Helmet>
    );
}
