import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PizzaCard from "../../components/cards/PizzaCard";
import ContactSection from "../../components/sections/ContactSection";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import { PizzasList } from "../../components/utils/PizzasList";
import {
    ColorModeContext,
    WidthContext,
} from "../../components/utils/Providers";

export default function PizzaDetails() {
    const { width } = useContext(WidthContext);
    const { setColor } = useContext(ColorModeContext);
    const navigate = useNavigate();
    const { slug } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (ev) => {
        if (ev.target.name === "contact") navigate("/contact");
    };

    // Aplatir la liste de pizzas et trouver la pizza avec l'id correspondant
    const pizza = PizzasList.flat().find((pizza) => pizza.slug === slug);

    useEffect(() => {
        width >= 768 ? setColor("beige") : setColor("black");
    }, [width, setColor]);

    function getJsonLd(pizza) {
        return {
            "@context": "https://schema.org/",
            "@type": "Product",
            name:
                pizza.name.charAt(0).toUpperCase() +
                pizza.name.slice(1).toLowerCase(),
            image: pizza.imageWebp,
            description: pizza.description,
            brand: {
                "@type": "Brand",
                name: "Pizza Cook",
            },
            offers: {
                "@type": "Offer",
                url: "https://pizza-cook.fr/notre-carte/margarita",
                priceCurrency: "EUR",
                price: pizza.priceMin,
                availability: "https://schema.org/InStock",
                itemCondition: "https://schema.org/NewCondition",
            },
        };
    }

    return (
        <>
            <MetaTagsMaster
                title={`La pizza "${pizza.name}"`}
                description={pizza.description}
                keywords={`pizzas, pizza cook, restaurant," +
        " france, saint sylvain, seiches, anjou, ${pizza.name}, ${pizza.description}`}
                image={"https://www.pizza-cook.fr" + pizza.imageWebp}
                jsonLd={getJsonLd(pizza)}
            />
            {/* <dialog
        open={isOpen}
        onClick={handleModal}
        className="w-full sm:w-[500px] z-10 bg-transparent"
      >
        <div className="modal-container p-4 bg-white rounded-lg">
          <button
            onClick={handleModal}
            className="absolute top-4 right-4 text-2xl text-base-100"
          >
            X
          </button>
          <div className=" flex flex-col gap-4">
            <h2 className="uppercase">Informations allergènes</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem
              debitis doloribus dolorum esse mollitia quaerat tempora,
              voluptatibus! Accusantium dicta, et fugit id ipsa nam nisi, omnis
              porro reprehenderit saepe ullam?
            </p>
            <ul className="list-disc list-inside">
              <li>Arachide</li>
              <li>Gluten</li>
              <li>...</li>
            </ul>
          </div>
        </div>
      </dialog> */}

            <PizzaCard
                pizza={pizza}
                display="details"
                width={width}
                // onClick={handleModal}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
            {/*{width >= 768 && <PizzeriasSection toggle={toggle} setToggle={setToggle} width={width}/>}*/}
            {/*{width >= 768 && <ContactSection onClick={handleClick}/>}*/}
            {/*{width >= 768 && <Footer toggle={toggle}/>}*/}
            <PizzeriasSection width={width} />
            <ContactSection onClick={handleClick} />
        </>
    );
}
