import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Artisanal1 from "../../assets/img/artisanal-1.png";
import Artisanal1Webp from "../../assets/img/artisanal-1.webp";
import Artisanal2 from "../../assets/img/artisanal-2.png";
import Artisanal2Webp from "../../assets/img/artisanal-2.webp";
import Artisanal3 from "../../assets/img/artisanal-3.png";
import Artisanal3Webp from "../../assets/img/artisanal-3.webp";
import LeafGrey from "../../assets/img/leaf-grey.png";
import LeafGreyWebp from "../../assets/img/leaf-grey.webp";
import PepperGrey from "../../assets/img/pepper-grey.png";
import PepperGreyWebp from "../../assets/img/pepper-grey.webp";
import PizzaGrey from "../../assets/img/pizza-grey.png";
import PizzaGreyWebp from "../../assets/img/pizza-grey.webp";
import PizzaSliceGrey from "../../assets/img/pizza-slice-grey.png";
import PizzaSliceGreyWebp from "../../assets/img/pizza-slice-grey.webp";
import Product1 from "../../assets/img/product-1.png";
import Product1Webp from "../../assets/img/product-1.webp";
import Product2 from "../../assets/img/product-2.png";
import Product2Webp from "../../assets/img/product-2.webp";
import Product3 from "../../assets/img/product-3.png";
import Product3Webp from "../../assets/img/product-3.webp";
import SlicedTomatoGrey from "../../assets/img/sliced-tomato-grey.png";
import SlicedTomatoGreyWebp from "../../assets/img/sliced-tomato-grey.webp";
import ContactSection from "../../components/sections/ContactSection";
import GroupOrderSection from "../../components/sections/GroupOrderSection";
import HeaderInfos from "../../components/sections/HeaderInfos";
import PizzaCarrouselSection from "../../components/sections/PizzaCarrouselSection";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import SlidingMessage from "../../components/sections/SlidingMessage";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import { ColorModeContext } from "../../components/utils/Providers";

export default function PizzaCook() {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const { setColor } = useContext(ColorModeContext);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const handleClick = (ev) => {
    if (ev.target.name === "contact") {
      navigate("/contact");
    } else if (ev.target.name === "group") {
      navigate("/livraison");
    } else if (ev.target.name === "menu") {
      navigate("/notre-carte");
    }
  };

  useEffect(() => {
      setColor("white");
  }, [setColor]);

  return (
    <>
      <MetaTagsMaster
        title="L'entreprise"
        description="Pizzerias à Saint-Sylvain-d’Anjou et à Seiches-Sur-Le-Loir, laissez-vous tenter par nos pizzas gourmandes
          en Maine-et-Loire."
        keywords="produits frais, savoir-faire,pizzas, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <HeaderInfos
        title="Pizza Cook"
        description="Des pizzas artisanales à partir de produits sélectionnés avec soin. 
Pizzerias à Saint-Sylvain d’Anjou et Seiches-sur-le-Loir et bornes à pizzas dans tout l’Anjou"
        option="pizzacook"
        width={width}
      />
      <SlidingMessage />
      <section className="artisanal-section bg-white text-base-100 text-center">
        <div className="bg-white w-full flex justify-start pizza-slice-grey-artisanal-container">
          <picture className="pizza-slice-grey-artisanal">
            <source srcSet={PizzaSliceGreyWebp} type="image/webp" />
            <source srcSet={PizzaSliceGrey} type="image/png" />
            <motion.img
              initial={{
                scale: 0,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={PizzaSliceGrey}
              alt="Pizza Slice Grey"
              loading="lazy"
            />
          </picture>
        </div>
        <div className="artisanal-content-container flex flex-col">
          <h2 className="uppercase">Un savoir-faire artisanal</h2>
          <div className="first-artisanal-content flex flex-col items-center">
            <p className="max-w-[820px]">
              Chez Pizza Cook, nous avons choisi de mettre l'artisanat au cœur
              de notre travail quotidien, aussi bien pour nos pizzas à emporter
              dans nos pizzerias que pour celles de nos distributeurs. <br />{" "}
              <br />
              Notre pâte est pétrie sur place chaque jour dans nos
              établissements, assurant une fraîcheur et une texture inégalées
            </p>
          </div>
        </div>
        <div className="first-artisanal-img grid grid-cols-2 grid-rows-2">
          <picture className="pizza-grey-artisanal">
            <source srcSet={PizzaGreyWebp} type="image/webp" />
            <source srcSet={PizzaGrey} type="image/png" />
            <motion.img
              initial={{
                scale: 0,
                rotate: 0,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                rotate: 360,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={PizzaGrey}
              alt=""
              loading="lazy"
            />
          </picture>
          <picture className="artisanal-one">
            <source srcSet={Artisanal1Webp} type="image/webp" />
            <source srcSet={Artisanal1} type="image/png" />
            <motion.img
              initial={{
                scale: 0.5,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={Artisanal1}
              alt="Un pizzaïolo qui pétrit une pâte"
              loading="lazy"
            />
          </picture>
          <picture className="artisanal-two">
            <source srcSet={Artisanal2Webp} type="image/webp" />
            <source srcSet={Artisanal2} type="image/png" />
            <motion.img
              initial={{
                scale: 0.5,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={Artisanal2}
              alt="Un pizzaïolo qui découpe une pizza"
              loading="lazy"
            />
          </picture>
          <picture className="artisanal-three">
            <source srcSet={Artisanal3Webp} type="image/webp" />
            <source srcSet={Artisanal3} type="image/png" />
            <motion.img
              initial={{
                scale: 0.5,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={Artisanal3}
              alt="Une pizza qui sort du four à pain"
              loading="lazy"
            />
          </picture>
        </div>
        <div className="artisanal-content-container flex flex-col items-center">
          <h2 className="uppercase">
            Des produits frais et sélectionnés par nos soins
          </h2>
          <div className="second-artisanal-content flex flex-col">
            <p className="max-w-[820px]">
              Nous portons une grande attention à la qualité de nos ingrédients
              et de nos produits. Nous travaillons avec de la farine 100% bio et
              tous nos produits sont frais et sélectionnés avec attention.
            </p>
          </div>
        </div>
        <div className="second-artisanal-img grid grid-cols-2 grid-rows-2">
          <picture className="leaf-grey-artisanal">
            <source srcSet={LeafGreyWebp} type="image/webp" />
            <source srcSet={LeafGrey} type="image/png" />
            <motion.img
              initial={{
                x: width >= 768 ? -100 : -25,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={LeafGrey}
              alt=""
              loading="lazy"
            />
          </picture>
          <picture className="pepper-grey-artisanal">
            <source srcSet={PepperGreyWebp} type="image/webp" />
            <source srcSet={PepperGrey} type="image/png" />
            <motion.img
              initial={{
                x: width >= 768 ? 100 : 15,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={PepperGrey}
              alt=""
              loading="lazy"
            />
          </picture>
          <picture className="sliced-tomato-grey-artisanal">
            <source srcSet={SlicedTomatoGreyWebp} type="image/webp" />
            <source srcSet={SlicedTomatoGrey} type="image/png" />
            <motion.img
              initial={{
                y: -100,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={SlicedTomatoGrey}
              alt=""
              loading="lazy"
            />
          </picture>
          <picture className="product-one">
            <source srcSet={Product1Webp} type="image/webp" />
            <source srcSet={Product1} type="image/png" />
            <motion.img
              initial={{
                scale: 0.5,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={Product1}
              alt="Des ingrédients sur une table"
              loading="lazy"
            />
          </picture>
          <picture className="product-two">
            <source srcSet={Product2Webp} type="image/webp" />
            <source srcSet={Product2} type="image/png" />
            <motion.img
              initial={{
                scale: 0.5,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={Product2}
              alt="Des ingrédients dans des bols"
              loading="lazy"
            />
          </picture>
          <picture className="product-three">
            <source srcSet={Product3Webp} type="image/webp" />
            <source srcSet={Product3} type="image/png" />
            <motion.img
              initial={{
                scale: 0.5,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={Product3}
              alt="Une pizza cuite"
              loading="lazy"
            />
          </picture>
        </div>
      </section>
      <PizzaCarrouselSection onClick={handleClick} />
      <PizzeriasSection width={width} detailPizzeria />
      <GroupOrderSection onClick={handleClick} width={width} />
      <ContactSection onClick={handleClick} name="contact" />
    </>
  );
}
