import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeroCheese from "../../assets/img/hero/cheese.png";
import HeroCheeseWebp from "../../assets/img/hero/cheese.webp";
import HeroChili from "../../assets/img/hero/chili.png";
import HeroChiliWebp from "../../assets/img/hero/chili.webp";
import HeroFlour from "../../assets/img/hero/flour.png";
import HeroFlourWebp from "../../assets/img/hero/flour.webp";
import HeroGarlic from "../../assets/img/hero/garlic.png";
import HeroGarlicWebp from "../../assets/img/hero/garlic.webp";
import HeroLeaf from "../../assets/img/hero/leaf.png";
import HeroLeafWebp from "../../assets/img/hero/leaf.webp";
import HeroOnions from "../../assets/img/hero/onions.png";
import HeroOnionsWebp from "../../assets/img/hero/onions.webp";
import HeroPepper from "../../assets/img/hero/pepper.png";
import HeroPepperWebp from "../../assets/img/hero/pepper.webp";
import HeroPizza from "../../assets/img/hero/pizza.png";
import HeroPizzaWebp from "../../assets/img/hero/pizza.webp";
import HeroSeeds from "../../assets/img/hero/seeds.png";
import HeroSeedsWebp from "../../assets/img/hero/seeds.webp";
import HeroTomato from "../../assets/img/hero/tomato.png";
import HeroTomatoWebp from "../../assets/img/hero/tomato.webp";
import PizzaGrey from "../../assets/img/pizza-grey.png";
import PizzaGreyWebp from "../../assets/img/pizza-grey.webp";
import PizzaSliceGrey from "../../assets/img/pizza-slice-grey.png";
import PizzaSliceGreyWebp from "../../assets/img/pizza-slice-grey.webp";
import PizzaSkillsSmall from "../../assets/img/savoir-faire-pizza.png";
import PizzaSkillsSmallWebp from "../../assets/img/savoir-faire-pizza.webp";
import PizzaSkills from "../../assets/img/savoir-faire-pâte.png";
import PizzaSkillsWebp from "../../assets/img/savoir-faire-pâte.webp";
import TomatoGrey from "../../assets/img/tomato-grey.png";
import TomatoGreyWebp from "../../assets/img/tomato-grey.webp";
import MainBtn from "../../components/buttons/MainBtn";
import ContactSection from "../../components/sections/ContactSection";
import GroupOrderSection from "../../components/sections/GroupOrderSection";
import PizzaCarrouselSection from "../../components/sections/PizzaCarrouselSection";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import SlidingMessage from "../../components/sections/SlidingMessage";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import {
  ColorModeContext,
  WidthContext,
} from "../../components/utils/Providers";

export default function Landing() {
  const navigate = useNavigate();
  const { width } = useContext(WidthContext);
  const { setColor } = useContext(ColorModeContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (ev) => {
    if (ev.target.name === "contact") {
      navigate("/contact");
    } else if (ev.target.name === "group") {
      navigate("/livraison");
    } else if (ev.target.name === "skills") {
      navigate("/pizza-cook");
    } else if (ev.target.name === "menu") {
      navigate("/notre-carte");
    } else if (ev.target.name === "phone") {
      setIsModalOpen(true);
    }
  };

  // Fonction pour fermer la modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
      setColor("black");
  }, [setColor]);

  return (
    <>
      <MetaTagsMaster
        title="Accueil"
        description="Découvrez nos pizzerias à Saint-Sylvain-D'Anjou et Seiches-sur-le-Loir. Savourez nos pizzas artisanales, réalisées avec des ingrédients de qualité."
        keywords="pizzas, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <div className="main-landing-container">
        <section className="landing-header-section">
          <div className="landing-header-container">
            <div>
              <h1 className="text-white uppercase text-landing">
                Pizzas gourmandes et appétissantes
              </h1>
              <p className="text-xl mt-[24px]">
                Pizzerias à Saint-Sylvain d’Anjou et Seiches-sur-le-Loir <br />
                Bornes à pizzas dans tout l’Anjou
              </p>
            </div>
            <div className="flex w-full flex-col sm:flex-row  gap-4">
              <MainBtn
                value="Voir la carte"
                onClick={handleClick}
                name="menu"
              />
              <MainBtn
                className={
                  "!text-black bg-white btn-main flex items-center !w-fit"
                }
                value="Passer commande"
                icon={
                  <svg
                    style={{ pointerEvents: "none" }}
                    width="32"
                    height="30"
                    viewBox="0 0 45 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3747 27.9769L30.718 27.4452C30.1705 27.3809 29.6155 27.4415 29.0947 27.6225C28.574 27.8035 28.101 28.1002 27.7114 28.4902L24.338 31.8636C19.1336 29.2166 14.9033 24.9864 12.2564 19.7819L15.648 16.3902C16.4364 15.6019 16.8214 14.5019 16.693 13.3836L16.1614 8.76356C16.0574 7.86921 15.6282 7.04432 14.9555 6.44594C14.2827 5.84756 13.4134 5.51749 12.513 5.51856H9.34137C7.2697 5.51856 5.54637 7.24189 5.6747 9.31356C6.64637 24.9702 19.168 37.4736 34.8064 38.4452C36.878 38.5736 38.6014 36.8502 38.6014 34.7786V31.6069C38.6197 29.7552 37.2264 28.1969 35.3747 27.9769Z"
                      fill="#1d1d1b"
                    />
                  </svg>
                }
                onClick={handleClick}
                name="phone"
              />
              {isModalOpen && (
                <div className="modal-overlay">
                  <div className="modal-content relative">
                    <div
                      className="absolute top-[-15px] right-[-15px] cursor-pointer bg-red text-white p-2 rounded-full"
                      role="button"
                      aria-label="Fermer"
                      onClick={closeModal} // Add close button handler to close menu
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    </div>
                    <h3 className="text-4xl text-black">
                      Saint-Sylvain-D'Anjou
                    </h3>
                    <a
                      href="tel:+33241954053"
                      className="text-black pt-2 text-xl pb-[16px] block"
                    >
                      02 41 95 40 53
                    </a>
                    <br />
                    <h3 className="text-4xl text-black">Seiches-Sur-Le-Loir</h3>
                    <a
                      href="tel:+33241761082"
                      className="text-black pt-2 text-xl block"
                    >
                      02 41 76 10 82
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid-img-landing">
            <picture className="hero-pizza">
              <source srcSet={HeroPizza} type="image/png" />
              <source srcSet={HeroPizzaWebp} type="image/webp" />
              <motion.img
                initial={{
                  opacity: 0,
                  scale: 0,
                  rotate: 360,
                  x: -400,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  rotate: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroPizza}
                alt="Une moitié de pizza"
                loading="lazy"
                className="hero-pizza-img"
              />
            </picture>
            <picture className="hero-tomato">
              <source srcSet={HeroTomato} type="image/png" />
              <source srcSet={HeroTomatoWebp} type="image/webp" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroTomato}
                alt="Des tomates"
                loading="lazy"
                className="hero-tomato-img"
              />
            </picture>
            <picture className="hero-seeds">
              <source srcSet={HeroSeeds} type="image/png" />
              <source srcSet={HeroSeedsWebp} type="image/webp" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroSeeds}
                alt="Du poivre"
                loading="lazy"
                className="hero-seeds-img"
              />
            </picture>
            <picture className="hero-leaf">
              <source srcSet={HeroLeaf} type="image/png" />
              <source srcSet={HeroLeafWebp} type="image/webp" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroLeaf}
                alt="Du basilic"
                loading="lazy"
                className="hero-leaf-img"
              />
            </picture>
            <picture className="hero-pepper">
              <source srcSet={HeroPepper} type="image/png" />
              <source srcSet={HeroPepperWebp} type="image/webp" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroPepper}
                alt="Un poivron"
                loading="lazy"
                className="hero-pepper-img"
              />
            </picture>
            <picture className="hero-onions">
              <source srcSet={HeroOnions} type="image/png" />
              <source srcSet={HeroOnionsWebp} type="image/webp" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroOnions}
                alt="Un oignon"
                loading="lazy"
                className="hero-onions-img"
              />
            </picture>
            <picture className="hero-cheese">
              <source srcSet={HeroCheese} type="image/png" />
              <source srcSet={HeroCheeseWebp} type="image/webp" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroCheese}
                alt="Du fromage"
                loading="lazy"
                className="hero-cheese-img"
              />
            </picture>
            <picture className="hero-chili">
              <source srcSet={HeroChili} type="image/png" />
              <source srcSet={HeroChiliWebp} type="image/webp" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroChili}
                alt="Un piment"
                loading="lazy"
                className="hero-chili-img"
              />
            </picture>
            <picture className="hero-flour">
              <source srcSet={HeroFlour} type="image/png" />
              <source srcSet={HeroFlourWebp} type="image/webp" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroFlour}
                alt="De la farine"
                loading="lazy"
                className="hero-flour-img"
              />
            </picture>
            <picture className="hero-garlic">
              <source srcSet={HeroGarlic} type="image/png" />
              <source srcSet={HeroGarlicWebp} type="image/webp" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroGarlic}
                alt="De l'ail"
                loading="lazy"
                className="hero-garlic-img"
              />
            </picture>
          </div>
        </section>
        <PizzaCarrouselSection onClick={handleClick} />
        {/* <SavoirFaireSection onClick={handleClick} width={width} /> */}
        <section className="savoir-faire-section">
          {width < 820 && (
            <div className="savoir-faire-img flex pl-[1.875rem] justify-between overflow-hidden">
              <picture className="pizza-sliced-grey">
                <source srcSet={PizzaSliceGreyWebp} type="image/webp" />
                <source srcSet={PizzaSliceGrey} type="image/png" />
                <motion.img
                  initial={{
                    opacity: 0,
                    scale: 0.5,
                  }}
                  whileInView={{
                    scale: 1,
                    opacity: 1,
                  }}
                  viewport={{ once: true, amount: 0.3 }}
                  transition={{
                    duration: 1.5,
                    ease: "backInOut",
                  }}
                  src={PizzaSliceGrey}
                  alt=""
                  loading="lazy"
                />
              </picture>
              <picture className="pizza-grey">
                <source srcSet={PizzaGreyWebp} type="image/webp" />
                <source srcSet={PizzaGrey} type="image/png" />
                <motion.img
                  initial={{
                    opacity: 0,
                    scale: 0.5,
                  }}
                  whileInView={{
                    scale: 1,
                    opacity: 1,
                  }}
                  viewport={{ once: true, amount: 0.3 }}
                  transition={{
                    duration: 1.5,
                    ease: "backInOut",
                  }}
                  src={PizzaGrey}
                  alt=""
                  loading="lazy"
                />
              </picture>
            </div>
          )}
          <div className="img-desktop-savoir-faire-container">
            {width >= 820 && (
              <div className="flex justify-between">
                <picture className="pizza-sliced-grey">
                  <source srcSet={PizzaSliceGreyWebp} type="image/webp" />
                  <source srcSet={PizzaSliceGrey} type="image/png" />
                  <motion.img
                    initial={{
                      opacity: 0,
                      scale: 0.5,
                    }}
                    whileInView={{
                      scale: 1,
                      opacity: 1,
                    }}
                    viewport={{ once: true, amount: 0.3 }}
                    transition={{
                      duration: 1.5,
                      ease: "backInOut",
                    }}
                    src={PizzaSliceGrey}
                    alt=""
                    loading="lazy"
                  />
                </picture>
                <picture className="tomato-grey">
                  <source srcSet={TomatoGreyWebp} type="image/webp" />
                  <source srcSet={TomatoGrey} type="image/png" />
                  <motion.img
                    initial={{
                      opacity: 0,
                      scale: 0.5,
                    }}
                    whileInView={{
                      scale: 1,
                      opacity: 1,
                    }}
                    viewport={{ once: true, amount: 0.3 }}
                    transition={{
                      duration: 1.5,
                      ease: "backInOut",
                    }}
                    src={TomatoGrey}
                    alt=""
                    loading="lazy"
                  />
                </picture>
              </div>
            )}
            <div className="flex flex-col savoir-faire-container-content">
              <h2 className="uppercase text-base-100">
                Un savoir-faire artisanal
              </h2>
              <p className="text-base-100 mr-[64px]">
                Chez Pizza Cook, nous avons choisi de mettre l'artisanat au cœur
                de notre travail quotidien, aussi bien pour nos pizzas à
                emporter dans nos pizzerias que pour celles de nos
                distributeurs. <br /> <br /> Notre pâte est pétrie sur place
                chaque jour dans nos établissements, assurant une fraîcheur et
                une texture inégalées. Nous attachons une grande importance à la
                qualité de nos ingrédients et de nos produits : nous utilisons
                exclusivement de la farine 100 % bio et sélectionnons avec soin
                des produits frais, gages de saveur et d'authenticité.
              </p>
              <MainBtn
                value="En savoir plus"
                name="skills"
                onClick={handleClick}
              />
            </div>
            {width >= 820 && (
              <div className="flex justify-end">
                <picture className="pizza-grey">
                  <source srcSet={PizzaGreyWebp} type="image/webp" />
                  <source srcSet={PizzaGrey} type="image/png" />
                  <motion.img
                    initial={{
                      rotate: 0,
                      opacity: 0,
                      scale: 0.5,
                    }}
                    whileInView={{
                      rotate: 360,
                      scale: 1,
                      opacity: 1,
                    }}
                    viewport={{ once: true, amount: 0.3 }}
                    transition={{
                      duration: 1.5,
                      ease: "backInOut",
                    }}
                    src={PizzaGrey}
                    alt=""
                    loading="lazy"
                  />
                </picture>
              </div>
            )}
          </div>
          <div className="savoir-faire-container-img grid lg:grid-cols-1 lg:grid-rows-3">
            <picture className="pizza-skills">
              <source srcSet={PizzaSkillsWebp} type="image/webp" />
              <source srcSet={PizzaSkills} type="image/png" />
              <motion.img
                initial={{
                  opacity: 0,
                  x: -100,
                }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={PizzaSkills}
                alt="Un pizzaïolo qui pétrit la pâte"
                loading="lazy"
              />
            </picture>
            <picture className="pizza-skills-small h-full z-10">
              <source srcSet={PizzaSkillsSmallWebp} type="image/webp" />
              <source srcSet={PizzaSkillsSmall} type="image/png" />
              <motion.img
                initial={{
                  opacity: 0,
                  x: 100,
                }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={PizzaSkillsSmall}
                alt="Une pizza qui sort du four à pain"
                loading="lazy"
              />
            </picture>
          </div>
        </section>
        <SlidingMessage />
        <PizzeriasSection
          distributeur={true}
          width={width}
          desc="Pizzas à emporter"
        />
        <GroupOrderSection onClick={handleClick} width={width} />
        <ContactSection onClick={handleClick} />
      </div>
    </>
  );
}
