import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import MushroomGrey from "../../assets/img/mushroom-grey.png";
import MushroomGreyWebp from "../../assets/img/mushroom-grey.webp";
import PizzaBoxGrey from "../../assets/img/pizza-box-grey.png";
import PizzaBoxGreyWebp from "../../assets/img/pizza-box-grey.webp";
import PizzaCard from "../cards/PizzaCard";
import { PizzasListBorn } from "../utils/PizzasListBorn";
import { ColorModeContext, WidthContext } from "../utils/Providers";

import SwiperCore, { EffectCoverflow, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

export default function PizzaDistriCarrouselSection({ onClick }) {
  const [display, setDisplay] = useState("");
  const { width } = useContext(WidthContext);
  const { color } = useContext(ColorModeContext);
  const isApple = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);
  return (
    <section className="bg-white pizza-carrousel-section pb-[52px]">
      {width >= 768 && (
        <div className="pizza-carrousel-img flex justify-end">
          <picture className="pizza-box-grey">
            <source srcSet={PizzaBoxGreyWebp} type="image/webp" />
            <source srcSet={PizzaBoxGrey} type="image/png" />
            <motion.img
              initial={{
                rotate: 0,
                scale: 0,
                opacity: 0,
              }}
              whileInView={{
                rotate: 360,
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={PizzaBoxGrey}
              alt=""
              loading="lazy"
            />
          </picture>
        </div>
      )}
      {width < 768 && color === "white" && (
        <div className="pizza-carrousel-img flex justify-end">
          <picture className="pizza-box-grey">
            <source srcSet={PizzaBoxGreyWebp} type="image/webp" />
            <source srcSet={PizzaBoxGrey} type="image/png" />
            <motion.img
              initial={{
                rotate: 0,
                scale: 0,
                opacity: 0,
              }}
              whileInView={{
                rotate: 360,
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={PizzaBoxGrey}
              alt=""
              loading="lazy"
            />
          </picture>
        </div>
      )}
      <div
        className={`flex flex-col items-center ${
          color === "black"
            ? "pizza-carousel-content-black"
            : "pizza-carousel-content"
        }`}
      >
        <h2 className="uppercase text-base-100 !mb-[32px]">Nos pizzas</h2>
        <p className="text-black mb-[52px] text-center">
          Découvrez la liste des pizzas disponibles dans nos bornes
          libre-service, téléchargez l'application{" "}
          <Link
            className="underline"
            target="_blank"
            rel="norefferer"
            to={
              isApple
                ? "https://apps.apple.com/fr/app/smart-pizza/id1603849374"
                : "https://play.google.com/store/apps/details?id=com.apitech.smart_pizza&hl=fr"
            }
          >
            SmartPizza
          </Link>{" "}
          et passez commande*
          <br />
          <span className="text-gray-500 text-[12px]">
            *Sous réserve des stocks disponibles
          </span>
        </p>
        <Swiper
          navigation
          loop={true}
          slidesPerView={1}
          spaceBetween={15}
          centeredSlides={false}
          breakpoints={{
            375: {
              slidesPerView: 1.1,
              spaceBetween: 30,
            },
            450: {
              slidesPerView: 1.5,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2.1,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3.1,
              spaceBetween: 30,
            },
            1440: {
              slidesPerView: 5.1,
              spaceBetween: 30,
            },
            1920: {
              slidesPerView: 5.1,
              spaceBetween: 30,
            },
          }}
          className="pizza-swiper py-[24px]"
        >
          {PizzasListBorn.flat().map((pizza) => (
            <SwiperSlide key={pizza.id}>
              <PizzaCard pizza={pizza} />
            </SwiperSlide>
          ))}
        </Swiper>
        {color === "white" && (
          <picture className="mushroom-grey absolute bottom-0 left-0">
            <source srcSet={MushroomGreyWebp} type="image/webp" />
            <source srcSet={MushroomGrey} type="image/png" />
            <motion.img
              initial={{
                x: width >= 768 ? -100 : -25,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={MushroomGrey}
              alt=""
              loading="lazy"
            />
          </picture>
        )}
      </div>
    </section>
  );
}
