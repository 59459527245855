import QuatreFromagesPng from "../../assets/img/pizza-png/4-fromages.png";
import BurgerPng from "../../assets/img/pizza-png/burger.png";
import ForestierePng from "../../assets/img/pizza-png/forestiere.png";
import GauthierPng from "../../assets/img/pizza-png/gauthier.png";
import IndiennePng from "../../assets/img/pizza-png/indienne.png";
import KebabPng from "../../assets/img/pizza-png/kebab.png";
import NormandePng from "../../assets/img/pizza-png/normande.png";
import RaclettePng from "../../assets/img/pizza-png/raclette.png";
import RillaudPng from "../../assets/img/pizza-png/rillaud.png";
import TartiflettePng from "../../assets/img/pizza-png/tartiflette.png";

import QuatreFromagesWebp from "../../assets/img/pizza-webp/4-fromages.webp";
import BurgerWebp from "../../assets/img/pizza-webp/burger.webp";
import ForestiereWebp from "../../assets/img/pizza-webp/forestiere.webp";
import GauthierWebp from "../../assets/img/pizza-webp/gauthier.webp";
import IndienneWebp from "../../assets/img/pizza-webp/indienne.webp";
import KebabWebp from "../../assets/img/pizza-webp/kebab.webp";
import NormandeWebp from "../../assets/img/pizza-webp/normande.webp";
import RacletteWebp from "../../assets/img/pizza-webp/raclette.webp";
import RillaudWebp from "../../assets/img/pizza-webp/rillaud.webp";
import TartifletteWebp from "../../assets/img/pizza-webp/tartiflette.webp";

export const PizzasListBorn = [
  [
    {
      id: 1,
      slug: "burger",
      name: "Burger",
      description:
        "Tomate, mozzarella, viande hachée, cheddar, sauce barbecue, oignons",
      imagePng: BurgerPng,
      imageWebp: BurgerWebp,
      category: "adulte",
    },
    {
      id: 2,
      slug: "kebab",
      name: "Kebab",
      description:
        "Tomate, mozzarella, pomme de terre, viande kebab, sauce blanche",
      imagePng: KebabPng,
      imageWebp: KebabWebp,
      category: "adulte",
    },
    {
      id: 3,
      slug: "indienne",
      name: "Indienne",
      description:
        "Crème fraîche, mozzarella, pomme de terre, poulet, sauce curry",
      imagePng: IndiennePng,
      imageWebp: IndienneWebp,
      category: "adulte",
    },
    {
      id: 4,
      slug: "4-fromages",
      name: "4 Fromages",
      description:
        "Tomate, mozzarella, chèvre, fromage de Savoie, bleu d'auvergne",
      imagePng: QuatreFromagesPng,
      imageWebp: QuatreFromagesWebp,
      category: "adulte",
    },
    {
      id: 5,
      slug: "tartiflette",
      name: "Tartiflette",
      description:
        "Tomate, mozzarella, pomme de terre, lardons, fromage de Savoie, oignons, crème fraîche",
      imagePng: TartiflettePng,
      imageWebp: TartifletteWebp,
      category: "adulte",
    },
    {
      id: 6,
      slug: "forestiere",
      name: "Forestière",
      description: "Tomate, mozzarella, champignons, lardons, crème fraîche",
      imagePng: ForestierePng,
      imageWebp: ForestiereWebp,
      category: "adulte",
    },
    {
      id: 7,
      slug: "normande",
      name: "Normande",
      description:
        "Crème fraîche, mozzarella, pomme de terre, lardons, camembert",
      imagePng: NormandePng,
      imageWebp: NormandeWebp,
      category: "adulte",
    },
    {
      id: 8,
      slug: "rillaud",
      name: "Rillaud",
      description:
        "Tomate, mozzarella, pomme de terre, rillauds, crème fraîche, oignons",
      imagePng: RillaudPng,
      imageWebp: RillaudWebp,
      category: "adulte",
    },
    {
      id: 9,
      slug: "raclette",
      name: "Raclette",
      description:
        "Crème fraîche, mozzarella, pomme de terre, jambon blanc, jambon cru, raclette",
      imagePng: RaclettePng,
      imageWebp: RacletteWebp,
      category: "adulte",
    },
    {
      id: 10,
      slug: "classique",
      name: "Classique",
      description: "Tomate, mozzarella",
      imagePng: GauthierPng,
      imageWebp: GauthierWebp,
      category: "adulte",
    },
  ],
];
